import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Container, Logo } from "./styles";
import sorveflixlogo from "../../assets/icon.png";
import loading from "../../assets/loading.gif";

const Component: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    setTimeout(() => {
      history.push("/home");
    }, 2000);
  });

  return (
    <Container>
      <Logo src={sorveflixlogo} alt="sorveflix" />
      <Logo src={loading} alt="sorveflix" />
    </Container>
  );
};

export default Component;
