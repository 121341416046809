import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;

export const Header = {
  Content: styled.header`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 4rem;
  `,
  Title: styled.span`
    font-family: "Bebas Neue";
    font-size: 2rem;
  `,
};

export const Title = styled.span`
  font-family: "Bebas Neue";
  font-size: 2rem;
`;

export const Content = {
  Title: styled.span`
    font-family: "Roboto";
    font-size: 1rem;
    padding: 1rem;
  `,

  Body: styled.div`
    display: flex;
    flex-direction: column;
  `,
};

export const Home = { Container, Content, Header, Title };
