import styled from "styled-components";

const Container = styled.div`
  font-family: "Roboto";
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  height: 100%;
`;

export const Banner = {
  Container: styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 420px;
    height: 420px;
    .return {
      font-size: 1.4rem;
      position: absolute;
      left: 1rem;
      top: 1rem;
      cursor: pointer;
      transition: filter 0.2s;
      :hover {
        filter: brightness(90%);
      }
    }
    .logo {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      right: 0.4rem;
      top: 0.2rem;
      img {
        width: 100px;
      }
    }
  `,
  Content: styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: -0.2rem;
    width: 100%;
    height: 8rem;
    .progress {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem 0rem;
      .bar {
        margin-right: 0.5rem;
        background: rgb(255, 0, 0);
        background: linear-gradient(
          90deg,
          rgba(255, 0, 0, 1) 44%,
          rgba(112, 112, 112, 0.6) 44%
        );
        width: 220px;
        height: 0.2rem;
      }
    }
    .player {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 300px;
      .start {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background-color: white;
        color: black;
        border-radius: 0.2rem;
        width: 10rem;
        height: 2.6rem;
        cursor: pointer;
        transition: filter 0.2s;
        :hover {
          filter: brightness(90%);
        }
      }
      .add,
      .like,
      .notlike {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid white;
        border-radius: 4rem;
        font-size: 1.4rem;
        margin: 0rem 0.4rem;
        min-height: 2.6rem;
        min-width: 2.6rem;
        transition: filter 0.2s;
        :hover {
          filter: brightness(90%);
        }
      }
    }
  `,
};

export const Description = {
  Container: styled.div`
    min-height: 320px;
    height: 100%;
    width: 100%;
  `,
  Header: styled.div`
    display: flex;
    align-items: center;
    height: 4rem;
    width: 100%;

    .banner-year {
      padding: 0rem 1rem;
    }
    .banner-classification {
      margin: 0rem 1rem;
      width: 30px;
      height: 30px;
    }
  `,
  Body: styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    padding: 0rem 1rem;
    height: 10rem;
  `,
  Footer: styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    height: 4rem;
    padding: 0rem 1rem;
    .text {
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: center;
    }
    .group {
      width: 10rem;
    }
  `,
};

export const Image = styled.img`
  min-width: 320px;
  height: 50%;
`;

const Product = { Container, Banner, Description, Image };
export default Product;
