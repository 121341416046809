import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "../pages/Home";
import Splashscreen from "../pages/Splashscreen";
import Product from "../pages/Product";

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Splashscreen} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/product" component={Product} />
      <Route path="*" component={Splashscreen} />
    </Switch>
  </Router>
);

export default Routes;
