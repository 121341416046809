import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Product from "./styles";
import { products } from "./products";
import classification from "../../assets/classificacao.png";

import Icon from "../../components/Icons";
import sorveflixlogo from "../../assets/icon.png";

const PageProduct: React.FC = () => {
  const history = useHistory();
  const fullYear = new Date().getFullYear();
  const [pageId, setPageId] = useState(0);

  useEffect(() => {
    let page = Number(sessionStorage.getItem("pageId"));
    page ? setPageId(page) : setPageId(0);
  }, []);

  return (
    <Product.Container>
      <Product.Banner.Container className={`${products[pageId].classBanner}`}>
        <div className="return" onClick={() => history.push("/home")}>
          <Icon name="arrow" />
        </div>
        <div className="logo" onClick={() => history.push("/home")}>
          <img src={sorveflixlogo} alt="" />
        </div>
        <Product.Image
          src={products[pageId].bannerLogo}
          alt={products[pageId].name}
        />
        <Product.Banner.Content className="gradient-player">
          <div className={`progress ${products[pageId].classBannerText}`}>
            <div className="bar" />
            <span>19 de 43min</span>
          </div>
          <div className="player">
            <span className="start">
              <Icon name="play" /> Continuar
            </span>
            <span className="add">
              <Icon name="add" />
            </span>
            <span className="like">
              <Icon name="like" />
            </span>
            <span className="notlike">
              <Icon name="notlike" />
            </span>
          </div>
        </Product.Banner.Content>
      </Product.Banner.Container>
      <Product.Description.Container className="gradient">
        <Product.Description.Header>
          <span className="banner-year">{fullYear}</span>
          <img
            className="banner-classification"
            src={classification}
            alt="livre"
          />
          <span className="banner-year">1 Temporada</span>
        </Product.Description.Header>
        <Product.Description.Body>
          <span style={{ fontSize: 12 }}> Sabor:</span>
          <span>{products[pageId].flavor}</span>
          <br />
          <span style={{ fontSize: 12 }}> Adicionais:</span>
          <span>{products[pageId].additional}</span>
        </Product.Description.Body>
        <Product.Description.Footer>
          <span style={{ fontSize: 12 }}> Preços:</span>
          <div className="group">
            {products[pageId].options.sorvete.price ? "Sorvete: " : ""}
            {products[pageId].options.sorvete.price}
          </div>
          <div className="group">
            {products[pageId].options.milkshake.price ? "Milkshake: " : ""}
            {products[pageId].options.milkshake.price}
          </div>
        </Product.Description.Footer>
      </Product.Description.Container>
    </Product.Container>
  );
};

export default PageProduct;
