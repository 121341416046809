import React from "react";
import { useHistory } from "react-router-dom";

import { Banner } from "./styles";
import { products } from "../../pages/Product/products";

type categories = "popular" | "new";

interface IBannerProps {
  category: categories;
}

const ComponentCard: React.FC<IBannerProps> = (props) => {
  const history = useHistory();
  function setPageId(key: string) {
    sessionStorage.setItem("pageId", key);
    setTimeout(() => {
      history.push("/product");
    }, 500);
  }

  return (
    <Banner.Container id="banners">
      {products.map((product, index) => {
        if (product.category === props.category) {
          return (
            <Banner.Content
              id={`banner-${index}`}
              onClick={() => setPageId(String(index))}
            >
              <Banner.Image src={product.banner} alt={product.name} />
            </Banner.Content>
          );
        } else {
          return null;
        }
      })}
    </Banner.Container>
  );
};

export default ComponentCard;
