import React, { useEffect } from "react";

import { Home } from "./styles";
import Banner from "../../components/Banner";

interface IPropsHome {}

const Component: React.FC<IPropsHome> = () => {
  useEffect(() => {
    const pageId = sessionStorage.getItem("pageId");
    const element = document.getElementById(`banner-${pageId}`);
    element?.scrollIntoView({
      block: "end",
      inline: "center",
      behavior: "smooth",
    });
  });

  return (
    <Home.Container>
      <Home.Header.Content>
        <Home.Header.Title className="text-red">Cardápio</Home.Header.Title>
      </Home.Header.Content>
      <Home.Content.Body className="">
        <Home.Content.Title>Populares na Sorveflix</Home.Content.Title>
        <Banner category="popular" />
      </Home.Content.Body>
    </Home.Container>
  );
};

export default Component;
