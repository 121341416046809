import styled from "styled-components";

export const Container = styled.div`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(75, 75, 75, 1) 40%,
    rgba(75, 75, 75, 1) 50%,
    rgba(0, 0, 0, 1) 100%
  );
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0rem 1rem;
  width: 100vw;
  min-height: 80vh;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 4rem;
`;

export const Title = styled.span`
  font-family: "Bebas Neue";
  font-size: 2rem;
`;

export const Content = styled.div`
  display: flex;
  width: 40rem;
  margin: 0rem 0.5rem;
`;

export const Image = styled.img`
  border-radius: 0.5rem;
  width: 250px;
  height: 428px;
  transition: all 0.5s;

  &:hover {
    width: 270px;
    height: 448px;
  }
`;

export const Banner = { Container, Content, Header, Title, Image };
