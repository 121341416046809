import { IProducts } from "./IProducts";

import greysamendoin_logo from "../../assets/logos/Grey's Amendoin_logo.png";
import gameoforeo_logo from "../../assets/logos/Game Of Oreo_logo.png";
import golaco_logo from "../../assets/logos/Golaço_logo.png";
import strangerfini_logo from "../../assets/logos/Stranger Fini_logo.png";
import sonhodevinkings_logo from "../../assets/logos/Vikings_logo.png";
import divertidamente_logo from "../../assets/logos/Divertidamente_logo.png";
import bisabis_logo from "../../assets/logos/BISaBIS_logo.png";
import unicornio_logo from "../../assets/logos/Unicórnio_logo.png";
// import a_culpa_das_cerejas_logo from "../../assets/logos/A culpa é das Cerejas.png";

import Golaço from "../../assets/banners/Golaço.png";
import BISaBIS from "../../assets/banners/BISaBIS.png";
import Unicornio from "../../assets/banners/Unicórnio.png";
import BabyYoda2 from "../../assets/banners/Baby Yoda 2.png";
import GameOfOreo from "../../assets/banners/Game Of Oreo.png";
import HarryPotter from "../../assets/banners/Harry Potter.png";
import StrangerFini from "../../assets/banners/Stranger Fini.png";
import GreysAmendoin from "../../assets/banners/Grey's Amendoin.png";
import Divertidamente from "../../assets/banners/Divertidamente.png";
import TheBrownieDead from "../../assets/banners/The Brownie Dead.png";
import ChocoballHolmes from "../../assets/banners/Chocoball Holmes.png";
import SonhodeVinkings from "../../assets/banners/Sonho de Vinkings.png";
import ACulpaDasCerejas from "../../assets/banners/A culpa é das Cerejas.png";
import AmericanMorangoStory from "../../assets/banners/American Morango Story.png";

export const products: IProducts[] = [
  {
    name: "BISaBIS",
    banner: BISaBIS,
    bannerLogo: bisabis_logo,
    category: "popular",
    flavor: "Doce de Leite",
    additional: "Bis, ganache de chocolate, cobertura de chocolate e chantily.",
    options: {
      sorvete: { price: "R$ 18" },
      milkshake: { price: "R$ 20" },
    },
    classBanner: "bis-a-bis",
    classBannerText: "txt-white",
    images: [
      {
        key: 1,
        src: "",
      },
    ],
  },
  {
    name: "Stranger Fini",
    banner: StrangerFini,
    bannerLogo: strangerfini_logo,
    category: "popular",
    flavor: "Baunilha",
    additional: "Fini, ganache de chocolate, cobertura de chocolate e chantily",
    options: {
      sorvete: { price: "R$ 18" },
      milkshake: { price: "R$ 20" },
    },
    classBanner: "stranger-fini",
    classBannerText: "txt-white",
    images: [
      {
        key: 1,
        src: "",
      },
    ],
  },
  {
    name: "A culpa é das Cerejas",
    banner: ACulpaDasCerejas,
    bannerLogo: "divertidamente_logo",
    category: "popular",
    flavor: "Morango",
    additional:
      "Cereja e marshmallow, ganache de chocolate branco e confete de coração, chantily.",
    options: {
      sorvete: { price: "R$ 18" },
      milkshake: { price: "R$ 20" },
    },
    classBanner: "divertidamente",
    classBannerText: "txt-white",
    images: [
      {
        key: 1,
        src: "",
      },
    ],
  },
  {
    name: "Chocoball Holmes",
    banner: ChocoballHolmes,
    bannerLogo: "divertidamente_logo",
    category: "popular",
    flavor: "Chocolate",
    additional: "Chocoball, brigadeiro e chocoball pequeno, chantily.",
    options: {
      sorvete: { price: "R$ 18" },
      milkshake: { price: "R$ 20" },
    },
    classBanner: "divertidamente",
    classBannerText: "txt-white",
    images: [
      {
        key: 1,
        src: "",
      },
    ],
  },
  {
    name: "Sonho de Vikings",
    banner: SonhodeVinkings,
    bannerLogo: sonhodevinkings_logo,
    category: "popular",
    flavor: "Chocolate",
    additional:
      "Chocoball, sonho de valsa, ganache de chocolate, cobertura de chocolate e chantily.",
    options: {
      sorvete: { price: "R$ 18" },
      milkshake: { price: "R$ 20" },
    },
    classBanner: "sonho-de-vinkings",
    classBannerText: "txt-white",
    images: [
      {
        key: 1,
        src: "",
      },
    ],
  },
  {
    name: "American Morango Story",
    banner: AmericanMorangoStory,
    bannerLogo: "sonhodevinkings_logo",
    category: "popular",
    flavor: "Morango com Nutella",
    additional:
      "Nutella e pedaços de morango, cobertura de chocolate e morango, chantily.",
    options: {
      sorvete: { price: "R$ 21" },
      milkshake: { price: "R$ 23" },
    },
    classBanner: "sonho-de-vinkings",
    classBannerText: "txt-white",
    images: [
      {
        key: 1,
        src: "",
      },
    ],
  },
  {
    name: "Baby Yoda",
    banner: BabyYoda2,
    bannerLogo: "sonhodevinkings_logo",
    category: "popular",
    flavor: "Menta com chocolate",
    additional: " Marshmallow e chocoball, ganache de chocolate, chantily.",
    options: {
      sorvete: { price: "R$ 21" },
      milkshake: { price: "R$ 23" },
    },
    classBanner: "sonho-de-vinkings",
    classBannerText: "txt-white",
    images: [
      {
        key: 1,
        src: "",
      },
    ],
  },
  {
    name: "Game Of Oreo",
    banner: GameOfOreo,
    bannerLogo: gameoforeo_logo,
    category: "popular",
    flavor: "Baunilha",
    additional:
      "Bolacha Oreo, ganache de chocolate branco e granulados de Oreo, cobertura de chocolate e chantily.",
    options: {
      sorvete: { price: "R$ 20" },
      milkshake: { price: "R$ 22" },
    },
    classBanner: "game-of-oreo",
    classBannerText: "txt-white",
    images: [
      {
        key: 1,
        src: "",
      },
    ],
  },
  {
    name: "Grey's Amendoim",
    banner: GreysAmendoin,
    bannerLogo: greysamendoin_logo,
    category: "popular",
    flavor: "Baunilha, chocolate ou morango.",
    additional: "Amendoim, ganache de chocolate, caramelo e chantily.",
    options: {
      sorvete: { price: "R$ 20" },
      milkshake: { price: "R$ 22" },
    },
    classBanner: "greys-anatomy",
    classBannerText: "txt-white",
    images: [
      {
        key: 1,
        src: "",
      },
    ],
  },
  {
    name: "Divertidamente",
    banner: Divertidamente,
    bannerLogo: divertidamente_logo,
    category: "popular",
    flavor: "Menta, chocolate, morango, doce de leite ou baunilha.",
    additional:
      "Pirulito colorido, ganache chocolate branco, confete colorido, chantily.",
    options: {
      sorvete: { price: "R$ 20" },
      milkshake: { price: "R$ 22" },
    },
    classBanner: "divertidamente",
    classBannerText: "txt-white",
    images: [
      {
        key: 1,
        src: "",
      },
    ],
  },
  {
    name: "Harry Potter",
    banner: HarryPotter,
    bannerLogo: "divertidamente_logo",
    category: "popular",
    flavor: "Leite ninho com avelã.",
    additional:
      "Calda de chocolate skimo e tubete, ganache de chocolate, flocos de chocolate.",
    options: {
      sorvete: { price: "R$ 22" },
      milkshake: { price: "R$ 24" },
    },
    classBanner: "divertidamente",
    classBannerText: "txt-white",
    images: [
      {
        key: 1,
        src: "",
      },
    ],
  },
  {
    name: "The Brownie Dead",
    banner: TheBrownieDead,
    bannerLogo: "divertidamente_logo",
    category: "popular",
    flavor: "Baunilha com doce de leite",
    additional: ": Brownie, cobertura de morango,bombom surpresa e chantily.",
    options: {
      sorvete: { price: "R$ 24" },
      milkshake: { price: "R$ 26" },
    },
    classBanner: "divertidamente",
    classBannerText: "txt-white",
    images: [
      {
        key: 1,
        src: "",
      },
    ],
  },
  {
    name: "Unicornio",
    banner: Unicornio,
    bannerLogo: unicornio_logo,
    category: "popular",
    flavor: "Morango",
    additional:
      "Marshmallow, ganache chocolate branco, cobertura de morango e chantily.",
    options: {
      sorvete: { price: null },
      milkshake: { price: "R$ 22" },
    },
    classBanner: "unicornio",
    classBannerText: "txt-white",
    images: [
      {
        key: 1,
        src: "",
      },
    ],
  },
  {
    name: "Golaço",
    banner: Golaço,
    bannerLogo: golaco_logo,
    category: "popular",
    flavor: "Baunilha ou Ovomaltine",
    additional:
      "Bolinha de chocolate, confete verde, cobertura de chocolate e chantily.",
    options: {
      sorvete: { price: null },
      milkshake: { price: "R$ 22" },
    },
    classBanner: "golaco",
    classBannerText: "txt-white",
    images: [
      {
        key: 1,
        src: "",
      },
    ],
  },
];
